<template>
<div id="stock-report-page" class="vs-row  w-full">
  <!-- BREADCRUMB -->
  <breadcrumb-base :breadcrumb="breadcrumb" :title="'Inventory'">
    <template v-slot:right-end>
      <vs-button class="mt-3 py-4 px-3" color="primary"
                 v-if="activeUserInfo.account_role == 0"
                 @click="openAddInventory">{{$t('AddInventory')}}
      </vs-button>
    </template>
  </breadcrumb-base>

  <transition name="fade">
    <div class="vx-row">
      <div class="vx-col md:w-1/5 mt-20">
        <vx-card>

          <div class="vs-col " v-if="productFilters">
            <label
              v-if="productFilters.length > 0"
              style="font-size: 12px; font-weight: bold">{{ $t('Product') }}
              <span
                @click="selectedProductFilter = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <vs-select
              autocomplete
              class="pr-4 w-full"
              v-model="selectedProductFilter"
              v-if="productFilters.length > 0"
              @input="onProductFilterChanged"
            >
              <vs-select-item :key="index" :value="product.product_id + 'product_id'"
                              :text="product.product_name" v-for="(product,index) in productFilters"  />
            </vs-select>
            <div class="py-2"></div>
          </div>

          <div class="vs-col " v-if="productFilters">
            <label
              v-if="productFilters.length > 0"
              style="font-size: 12px; font-weight: bold">{{ $t('Denomination') }}
              <span
                @click="selectedProductConfigFilter = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <vs-select
              autocomplete
              class="pr-4 w-full"
              v-model="selectedProductConfigFilter"
              v-if="productFilters.length > 0"
              @input="onProductFilterChanged"
            >
              <template v-for="(product,index) in productFilters">
                <template  v-if="selectedProductFilter && selectedProductFilter.indexOf(product.product_id) != -1">
                <vs-select-item
                  :key="index"
                  :value="config.product_configuration_value + 'product_configuration_value'"
                  :text="config.product_configuration_value"
                  v-for="(config,index) in product.configurations"  />
                </template>
              </template>
            </vs-select>
            <div class="py-2"></div>
          </div>

          <div class="py-2"></div>
          <vs-button class="mt-3 w-full py-4 px-3" color="primary"
                     @click="clearFilterData">{{$t('ClearAllFilters').toUpperCase()}}
          </vs-button>
        </vx-card>
      </div>
      <div class="vx-col md:w-4/5">

        <!--    Error List State-->
        <transition name="fade">
          <div class="py-6 flex w-full bg-img" v-if="errorFetching">
            <div
              class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
              <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
                   class="mx-auto mb-4 max-w-full">
              <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('ErrorData')}}</h1>
            </div>
          </div>
        </transition>
        <!--    Empty List State -->
        <transition name="fade">
          <div class="py-6 flex w-full bg-img" v-if="stock.length === 0 && !errorFetching">
            <div
              class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--              <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                   class="mx-auto mb-4 max-w-full">-->
              <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('NoData')}}</h1>
            </div>
          </div>
        </transition>
        <vs-table-modified class="flex-1-1 w-full md:w-2/3" v-show="stock.length > 0 && !errorFetching" ref="table"
                           :max-items="10"
                           :data="stock"
                           v-on:selected="selectProduct"
        >
          <template slot="thead">
            <vs-th  >{{$t('ProductImage')}}</vs-th>
            <vs-th >{{$t('Denomination')}}</vs-th>
            <vs-th >{{$t('AvelStock')}}</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

              <vs-td>
                <img :src="tr.product_logo" :alt="tr.product_name" class="product-image"></img>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.configuration_value }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name">{{ tr.total_unsold_count }}</p>
              </vs-td>

            </vs-tr>
            </tbody>
          </template>
          <template slot="footer">
          </template>
          <template slot="footer-left">
            <div class="flex">
              <vs-new-pagination :total="vTotalPages" v-model="currentPage" v-on:change="listPageChanged">
              </vs-new-pagination>
            </div>
          </template>
        </vs-table-modified>
      </div>
    </div>
  </transition>
</div>
</template>

<script>
import VxModal from '@/layouts/components/custom/VxModal'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

export default {
  name: 'Inventory',

  components: {
    VxModal,
    VueApexCharts,
  },
  data() {
    return {
      errorFetching: false,
      showSideBar: false,
      selectedProduct: null,
      productFilters: null,
      selectedProductFilter: null,
      selectedProductConfigFilter: null,
      stockReport: null,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Inventory', i18n: 'Inventory', active: true },
      ],
      currentPage: 1,
    }
  },
  computed: {
    stock() {
      return this.$store.state.reports.stock
    },
    vPage() {
      return this.$store.state.reports.vPage
    },
    vTotalPages() {
      return this.$store.state.reports.vTotalPages
    },
    vLimit() {
      return this.$store.state.reports.itemCount
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    openAddInventory() {
      this.$router.push({ name: 'inventory-add' })
    },
    openStockInventoryDetails() {
      this.$router.push({
        name: 'inventory-details',
        params: { configId: this.selectedProduct.configuration_id },
      })
    },
    loadMore() {
      this.fetchStock(this.getFilters(), true).then((data) => {
        this.$vs.loading.close()
        setTimeout(() => {
          this.currentPage = this.$refs.table.getTotalPages
        }, 1000)
      })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    clearFilterData() {
      this.selectedProductFilter = null
      this.selectedProductConfigFilter = null
      this.filterChanged()
    },
    getFilters() {
      const filter = {}
      if (this.selectedProductFilter) {
        filter.product_id = this.selectedProductFilter.replace('product_id', '')
      }
      if (this.selectedProductConfigFilter) {
        filter.product_configuration_value = this.selectedProductConfigFilter.replace('product_configuration_value', '')
      }
      return filter
    },
    filterChanged() {
      this.fetchStock(this.getFilters())
    },
    onProductFilterChanged() {
      this.filterChanged()
    },
    activateSideBar(status) {
      this.showSideBar = status
      if (!status) {
        this.selectedProduct = null
      }
    },
    selectProduct(event) {
      this.selectedProduct = event
      this.openStockInventoryDetails()
    },
    listPageChanged(item) {
      this.fetchStock(this.getFilters(), this.currentPage - 1)
    },
    fetchStock(payload = null, page = '0') {
      if (page == 0) this.currentPage = 1
      this.$vs.loading()
      return this.$store.dispatch('reports/fetchStockReports', { payload, isInitial: page == '0', page })
        .then((data) => {
          // if (data.message_type === 1) {
          //   try {
          //     this.productFilters = data.type_of_filters.products
          //   } catch (e) {}
          // }
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    this.$store.commit('reports/RESET')
    // this.fetchStock()
    this.$store.dispatch('reports/fetchProductFilters').then((data) => {
      if (data.message_type === 1) {
        try {
          this.productFilters = data.type_of_filters.products
        } catch (e) {
          this.productFilters = []
        }
      }
    })
  },
}
</script>

<style lang="scss">

.vs-sidebar.vs-sidebar-primary.vs-sidebar-position-right {
  max-width: 100vh !important;
}
.sidebar-product-image {
  /*max-width: 200px;*/
  max-height: 70px;
  object-fit: cover;
}


#stock-report-page {
  .product-image {
    /*max-width: 200px;*/
    max-height: 40px;
    object-fit: cover;
  }

  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .products-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }


}
</style>
